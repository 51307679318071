html,
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

.App {
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}


.inputRounded .MuiOutlinedInput-root {
  border-radius: 25px;
  border-color: #13cbc5;
  color: white;
  background-color: #6C6B6D;

}
.MuiInputBase-input{
  /* border-radius: 25px; */
  border-color: #13cbc5;
  color: white;
  background-color: #6C6B6D;
}
.MuiFormLabel-root.Mui-focused {
  color: white !important;
  background-color: #6C6B6D;
  
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #13cbc5 !important;
  
}
.MuiFormLabel-root.MuiInputLabel-root {
  color: white !important;
  
}
#app-table tr:nth-child(2n) {
  background-color: #e2e1e2;
  color: #000;
}

  .center-login {
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
  }

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #6C6B6D inset;
  transition: background-color 5000s ease-in-out 0s;
}